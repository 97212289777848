<template>
  <div class="inform">
    <div class="inform-main">
      <div class="empty_news">
        <div class="title">消息</div>
        <div style="display: flex; align-items: center">
          <div
            style="margin-right: 20px; display: flex; align-items: center"
            class="clear"
            @click="Emptymessage"
          >
            <i class="hy-icon-15qingkong-1"></i>全部已读
          </div>
          <div
            class="clear"
            style="display: flex; align-items: center"
            @click="clearRead"
          >
            <i class="el-icon-delete"></i>清空消息
          </div>
        </div>
      </div>

      <div class="empty" v-for="(item, index) in list" :key="index">
        <div class="img">
          <i class="el-icon-message"></i>
        </div>
        <div class="msg-main">
          <div class="msg-title">
            <div class="names">系统消息</div>
            <div class="date">
              <i class="hy-icon-shijian1"></i>{{ item.createTime }}
            </div>
          </div>
          <div class="time">
            <div class="content">{{ item.messageContent }}</div>
            <div class="empty_time">
              <!-- <div class="dot" v-if="item.readStatus == 0"></div> -->
            </div>
          </div>
        </div>
      </div>

      <div v-if="list.length == 0" style="margin: 0 auto; text-align: center">
        <div style="padding: 200px 0">
          <img src="../../assets/zxx_img/NOCollection.png" alt="" />
          <div style="color:#ccc">暂无消息</div>
        </div>
      </div>

      <div class="pagination">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          layout="prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getMessageList, updateAll, deleteAll } from "@/api/public";
import * as types from "@/store/action-types";
import Vue from "vue";
export default {
  components: {
    readcrumb: () => import("../../components/breadcrumb"),
  },
  data() {
    return {
      pagesize: 10,
      pagenum: 1,
      total: 0,
      list: [],
      breadcrumbDto: [
        { path: "/index", title: "首页" },
        { path: "/information", title: "消息" },
      ],
    };
  },
  mounted() {
    this.getlist();
  },
  methods: {
    Emptymessage() {
      if (!this.beforhandle("myNews:clearReade")) return;
      let config = {
        messageType: 0,
      };
      updateAll(config).then((res) => {
        if (res.code == 0) {
          this.$notify({
            title: "成功",
            message: "标记成功",
            type: "success",
            position: "bottom-right",
          });
          setTimeout((i) => {
            window.location.reload();
          }, 1000);
          this.getlist();
        }
      });
    },
    clearRead() {
      if (!this.beforhandle("myNews:clearReade")) return;
      let config = {
        messageType: 0,
      };
      deleteAll(config).then((res) => {
        if (res.code == 0) {
          this.$notify({
            title: "成功",
            message: "清空成功",
            type: "success",
            position: "bottom-right",
          });
          setTimeout((i) => {
            window.location.reload();
          }, 1000);
          this.getlist();
        }
      });
    },
    handleCurrentChange(val) {
      let id = Vue.ls.get(types.enterpriseId);
      let config = {
        enterpriseId: id,
        messageType: 0,
        page: val,
        size: this.pagesize,
      };
      getMessageList(config).then((res) => {
        console.log(res);
        if (res.code == 0) {
          let newlist = res.data.records;
          newlist.forEach((item) => {
            if (item.createTime) {
              item.createTime = item.createTime.replace("T", " ");
            }
          });
          this.total = res.data.total;
          this.list = newlist;
        }
      });
    },
    getlist() {
      let id = Vue.ls.get(types.enterpriseId);
      let config = {
        enterpriseId: id,
        messageType: 0,
        page: this.pagenum,
        size: this.pagesize,
      };
      getMessageList(config).then((res) => {
        console.log(res);
        if (res.code == 0) {
          let newlist = res.data.records;
          newlist.forEach((item) => {
            if (item.createTime) {
              item.createTime = item.createTime.replace("T", " ");
            }
          });
          this.total = res.data.total;
          this.list = newlist;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.inform {
  width: 1195px;
  margin: 20px auto;
  .inform-main {
    background-color: #ffffff;

    border: 1px solid #e5e5e5;
  }
  .empty_news {
    display: flex;
    justify-content: space-between;
    height: 47px;
    line-height: 47px;
    background-color: #f3f3f3;
    padding: 0 13px;
    i {
      font-size: 21px;
      color: #0a65ff;
      margin-right: 10px;
    }
    .clear {
      color: #0a65ff;
    }
    .title {
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
    }
    .empty_size {
      cursor: pointer;
      background: blue;
      padding: 10px 20px;
      color: white;
      border-radius: 10px;
    }
  }
  .empty {
    margin-top: 20px;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    margin: 0 13px;
    border-bottom: 1px solid #e5e5e5;
    padding: 23px 0;
    .img {
      display: flex;
      align-items: center;
      i {
        font-size: 24px;
        color: #0079fe;
      }
    }
    .time {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      .content {
        width: 1030px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .msg-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .names {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: bold;
      }
      .date {
        font-size: 14px;
        color: #999999;
        i {
          font-size: 16px;
          margin-right: 7px;
        }
      }
    }
    .msg-main {
      width: 1130px;
    }
    .empty_time {
      display: flex;
      // .dot {
      //   margin-top: 5px;
      //   margin-left: 10px;
      //   width: 10px;
      //   height: 10px;
      //   background: red;
      //   border-radius: 50%;
      // }
    }
  }
}
.pagination {
  text-align: right;
}
</style>
